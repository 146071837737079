import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConstructionInterface } from '../../interfaces/construction.interface';
import { getBoards } from '../../../auth/selectors/auth.selector';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ConstructionModel } from '../../models/construction.model';
import { ConstructionState } from '../../enums/state.enum';
import { UntypedFormGroup } from '@angular/forms';
import { ConstructionNotificationModel } from '../../models/construction-notification.model';
import {BaseUserService} from "../../../shared/services/base-user/base-user.service";
import {RequestQueryBuilder, SCondition} from "@nestjsx/crud-request";
import {get30DaysAgoDate} from "../../../shared/utils/service-utils";
import {IdAndNameModel} from "../../../shared/models/id-and-name.model";
import {UserModel} from "../../../user/models/user.model";
import {lastValueFrom} from "rxjs";
import dayjs from "dayjs";

@Injectable({
  providedIn: 'root',
})
export class ConstructionService {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private baseUserService: BaseUserService
  ) {}

  userHomeConstructionSearch(showingArchivedConstructionOnly: boolean, loggedUser?: UserModel): SCondition {
    return {
      $and: [
        { 'actorInConstructions.actorId': { $eq: loggedUser.baseActorId } },
        { 'actorInConstructions.archived': { $eq: showingArchivedConstructionOnly ? 1 : 0 } },
        { enabled: { $eq: 1 }},
        { invisible: 0 },
        {
          $or: [
            {
              constructionState: { $ne: ConstructionState.closed },
            },
            {
              $or: [
                { $and: [
                    { constructionStateUpdateDate: { $gt: get30DaysAgoDate().toISOString() } },
                    { constructionState: { $eq: ConstructionState.closed } },
                  ]
                },
                { $and: [
                    { constructionStateUpdateDate: { $eq: null } },
                    { constructionState: { $eq: ConstructionState.closed } },
                  ]
                }
              ]
            }
          ],
        },
      ],
    };
  }

  async activeUserConstructionsQueryFilter() {
    const loggedUser = await this.baseUserService.loggedUser();
    return [
      { field: "actorInConstructions.actorId", operator: "eq", value: loggedUser?.baseActorId },
      { field: "enabled", operator: "eq", value: true},
      { field: "invisible", operator: "eq", value: false}
    ] as any;
  }

  activeUserConstructionsJoin() {
    return [{ field: 'actorInConstructions'}, { field: 'notification' }, { field: 'dossiers' }] as any;
  }

  async activeUserConstructionsQueryBuilder() {
    return RequestQueryBuilder.create({
      filter: await this.activeUserConstructionsQueryFilter(),
      join: this.activeUserConstructionsJoin()
    });
  }

  async getUserConstructionsSorted(): Promise<ConstructionNotificationModel[]> {
    const qb = await this.activeUserConstructionsQueryBuilder();
    const constructions: ConstructionNotificationModel[] =  await this.getManyTipified(qb);
    return constructions.sort((a, b) =>
      this.getConstructionName(a).localeCompare(this.getConstructionName(b)));
  }

  async getManyTipified(qb: RequestQueryBuilder) {
    return (await this.getMany(qb.query()) as any)?.data;
  }

  async getConstructionWithActors(constructionId: number){
    const qb = new RequestQueryBuilder();
    qb.setJoin([{field: 'actorInConstructions'}, {field: 'actorInConstructions.actor'}]);
    return await this.getOne(constructionId, qb.query()).toPromise();
  }

  getOne(id: number, query?: string) {
    return this.http.get<ConstructionModel>(`${environment.BASE_PATH}/construction/${id}?${query || ''}`);
  }

  mapConstructionStartEndDates(construction: ConstructionModel, constructionFormGroup: UntypedFormGroup) {
    //essendo le date in Moment e venendo salvate a DB nel formato YYYY-MM-DD vanno formattate
    construction.startWorksDate = constructionFormGroup.get('startWorksDate')?.value ? dayjs(constructionFormGroup.get('startWorksDate')?.value)!.format('YYYY-MM-DD') : null;
    construction.endWorksDate = constructionFormGroup.get('endWorksDate')?.value ? dayjs(constructionFormGroup.get('endWorksDate')?.value)!.format('YYYY-MM-DD') : null;
  }

  async canUserEditConstruction(construction: ConstructionModel) {
    if (await this.baseUserService.isLoggedUserGuest()) {
      return false;
    }
    if (construction?.boardId) {
      const boards = await this.store.select(getBoards).pipe(take(1)).toPromise();
      return boards.includes(construction.boardId);
    }
    return false;
  }

  async getById(constructionId: string, joinActor?: boolean, joinConstructionContractor?: boolean, joinActorInConstructionAnomalyProcedure?: boolean, joinDossier?: boolean): Promise<ConstructionInterface> {
    let join = [
      { field: 'notification'},
      { field: 'actorInConstructions' },
      { field: 'notification.actorInNotifications' },
      { field: 'actorInConstructions.actor' },
      { field: 'actorInConstructions.actor.actorUser' },
    ]

    if (joinActor) {
      join.push({ field: 'notification.actorInNotifications.actor' });
    }
    if (joinConstructionContractor) {
      join.push({ field: 'constructionContractors' });
      join.push({ field: 'constructionContractors.actor' });
    }
    if (joinActorInConstructionAnomalyProcedure) {
      join.push({ field: 'actorInConstructions.anomalyProcedures' });
    }
    if (joinDossier) {
      join.push({ field: 'dossier' });
      join.push({ field: 'dossier.actorInDossiers' });
      join.push({ field: 'dossier.actorInDossiers.actor' });
    }
    const qb = RequestQueryBuilder.create({join: join});
    return await this.http.get<ConstructionInterface>(`${environment.BASE_PATH}/construction/${constructionId}?${qb.query()}`).toPromise();
  }

  isClosed(constructionModel: ConstructionModel) {
    return constructionModel.constructionState == ConstructionState.closed;
  }

  isSuspended(constructionModel: ConstructionModel) {
    return constructionModel.constructionState == ConstructionState.suspended;
  }

  getMany(query: string) {
    return this.http.get<{ data: ConstructionModel[] }>(`${environment.BASE_PATH}/construction/?${query}`).toPromise();
  }

  async createOrUpdate(construction: ConstructionModel): Promise<ConstructionModel> {
    const $result = this.http.post<ConstructionModel>(`${environment.BASE_PATH}/construction/`, construction);
    return await lastValueFrom($result);
  }

  getConstructionName(construction: ConstructionModel): string {
    let constructionName = '';
    if(construction.actorInConstructions?.length > 0) {
        if(construction.actorInConstructions[0]?.jobCode) constructionName += `${construction.actorInConstructions[0].jobCode} - `;
    }
    if(construction.description) constructionName += `${construction.description} `;
    constructionName += this.getConstructionCompleteAddress(construction);
    if(construction.customersText) constructionName += ` - ${construction.customersText}`;
    if(construction.notification?.notificationNumber) constructionName += ` - ${construction.notification.notificationNumber}`;
    return constructionName;
  }

  getConstructionCompleteAddress(construction: ConstructionModel): string {
    let constructionName = '';
    if(construction.address) constructionName += `${construction.address}, `;
    if(construction.city) constructionName += `${construction.city}`;
    return constructionName;
  }

  getConstructionsWithProgressPercentage(constructions: ConstructionModel[]) {
    return constructions.map((construction) => {
      if (construction.startWorksDate && construction.endWorksDate) {
        const totalDays = this.dateDiffInDays(new Date(construction.startWorksDate), new Date(construction.endWorksDate));

        let currentProgressDays = totalDays;
        if (new Date(construction.endWorksDate) > new Date()) {
          currentProgressDays = this.dateDiffInDays(new Date(construction.startWorksDate), new Date());
        }
        construction.remainingDays = totalDays - currentProgressDays;
        construction.workProgressPercentage = Math.round(100 / totalDays * currentProgressDays);
      }
      return construction;
    });
  }

  dateDiffInDays(startDate, endDate) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  async updateOne(constructionId: number, construction: Partial<ConstructionModel>) {
    const url = `${environment.BASE_PATH}/construction/${constructionId}`;
    const $result = this.http.patch<ConstructionModel>(url, construction);

    return await lastValueFrom($result);
  }

  async getAvailableEmployees(constructionId: number, boards: number[]): Promise<number> {
    return await this.http
      .post<any>(`${environment.BASE_PATH}/construction/report/get-available-employees`, {
        constructionId: constructionId,
        boards: boards,
      })
      .toPromise();
  }

  generateTableExcel(startDate: Date, endDate: Date) {
    const data = {startDate: startDate, endDate: endDate}
    return this.http.post(`${environment.BASE_PATH}/construction/export/table/excel`, data, {observe: "response", responseType:"blob"});
  }

  async getUserActiveConstructions() {
    const qb = RequestQueryBuilder.create({
      join: [{field: 'notification'}, {field: 'actorInConstructions'}, {field: 'dossiers'}],
      search: await this.getActiveConstructionSearch(),
    });

    return (await this.getMany(qb.query())).data;
  }

  async getUserActiveConstructionsIdsAndNames(): Promise<IdAndNameModel[]> {
    const qb = RequestQueryBuilder.create({
      join: [{field: 'notification'}, {field: 'actorInConstructions'}, {field: 'dossiers'}],
      search: await this.getActiveConstructionSearch(),
      fields: ['id', 'actorInConstructions', 'notification.notificationNumber', 'description', 'address', 'city', 'customersText']
    });

    const constructions = (await this.getMany(qb.query())).data;

    return constructions.map(c => ({
      id: c.id,
      name: this.getConstructionName(c)
    }))
  }

  async getActiveConstructionSearch(): Promise<SCondition> {
    const loggedUser = await this.baseUserService.loggedUser();

    return {
      $and: [
        {'actorInConstructions.actorId': {$eq: loggedUser?.baseActorId}},
        {'actorInConstructions.archived': {$eq: 0}},
        {enabled: {$eq: 1}},
        {invisible: 0},
        {
          $or: [
            {
              constructionState: {$ne: ConstructionState.closed},
            },
            {
              $or: [
                {
                  $and: [
                    {constructionStateUpdateDate: {$gt: get30DaysAgoDate().toISOString()}},
                    {constructionState: {$eq: ConstructionState.closed}},
                  ]
                },
                {
                  $and: [
                    {constructionStateUpdateDate: {$eq: null}},
                    {constructionState: {$eq: ConstructionState.closed}},
                  ]
                }
              ]
            }
          ],
        },
      ],
    }
  }
}
